<template>
  <div v-if="showConsent" class="overlay-container">
    <div class="consent-container">
        <img src="@assets/icon-consent-cookie.svg" />
        <div class="cookie-content">
          <h2>We use Cookies</h2>
          <p>
            We sprinkle cookies to give you the best experience—no crumbs,
            just smooth browsing! By accepting, you help us improve performance and personalize content.
            For more details, visit our Learn More page.
          </p>
        </div>
        <div class="btn-container">
            <button @click="setConsent('granted')">Accept</button>
            <a href="/v5/cookies-policy">Learn more</a>
        </div>
    </div>
  </div>
</template>
<style scoped lang="scss">

  .overlay-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(26, 26, 26, 0.70);
    z-index: var(--slidein-z-index);
  }
  .consent-container {
    padding: size(32) size(24);
    font-family: var(--rz-para-font);
    background: #ffffff;
    display: flex;
    flex-direction: column;
    max-width: size(275);
    text-align: center;
    align-items: center;
    border-radius: size(16);
    justify-content: center;
    z-index: calc(var(--slidein-z-index)+100);
    position: fixed;
    left: size(0);
    right: 0;
    gap: size(20);
    animation: slideup 1s ease-in-out;
    animation-fill-mode: forwards;
    transition: bottom 0.3s ease;
    box-shadow: 0 0 18px rgba(0,0,0,.2);

    @keyframes slideup {
      0%{
        bottom: size(-200);
      }
      100%{
        bottom: size(20);
      }
    }

    .cookie-content {
      max-width: 20rem;
      h2 {
        color: var(--rs-black-color);
        font-size: var(--rs-para-level0-font-size);
        line-height: normal;
        font-weight: 700;
        margin-bottom: size(6);
        padding: 0;
      }

      p {
        font-size: size(14);
        line-height: normal;
        font-weight: 400;
        color: var(--global--gray--Color);
      }
    }
    .btn-container {
      display: flex;
      flex-direction: column;
      gap: size(10);
      width: 100%;
      button, a {
        padding: size(12) size(16);
        border-radius: size(5);
        font-size: var(--rs-title-level5-font-size);
        line-height: normal;
        font-weight: 400;
        cursor: pointer;

        &:nth-child(1) {
          background-color: #111111;
          color: #ffffff;
        }
      }

      a {
        border: 1px solid #111111;
        padding: size(12) size(16);

        &:hover {
          color: #111111;
        }
      }
    }

    @media screen and (min-width:  $breakpoint-md) {
      text-align: center;
      gap: size(18);
      max-width: size(330);
      left: size(20);
      .cookie-content {
        max-width: 30rem;

        p{
          margin: 0;
        }
      }
    }

    @media screen and (min-width:  $breakpoint-lg) {
      .cookie-content {
        max-width: 40rem;
      }
    }
  }
</style>
<script>
export default {
  name: 'ConsentComponent',
  props: {
    showCountdownPopup: {
      type: Function,
    },
  },
  data() {
    return {
      showConsent: false,
    };
  },
  mounted() {
    if (navigator.brave) {
      navigator.brave.isBrave().then((brave) => {
        if (brave) {
          this.showConsent = false;
        }
      });
    } else if (localStorage.getItem('rzConsent') === null && this.$route.name !== 'rsCricketCookiesPolicy') {
      this.showConsent = true;
    }
  },
  methods: {
    setConsent(status) {
      const rzAppNameTM = 'RS_SYS_WEB';
      const dataLayer = [{ rzappname: rzAppNameTM }, { event: 'rz.app.prepare', rzappname: rzAppNameTM }];
      // eslint-disable-next-line prefer-rest-params
      function gtag() { dataLayer.push(arguments); }
      const consent = {
        ad_storage: status,
        analytics_storage: status,
        functionality_storage: status,
        personalization_storage: status,
        security_storage: status,
      };

      localStorage.setItem('rzConsent', window.btoa(JSON.stringify(consent)));
      gtag('consent', 'update', consent);
      this.showConsent = false;
      this.showCountdownPopup();
    },
  },
};
</script>
