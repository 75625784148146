<template>
  <div class="popup-container">
    <h2>
      <span>Grab</span>
      <span>30% Off</span>
    </h2>
    <p class="auction-tag">IPL Auction Special</p>
    <div class="time-container">
      <div>
          <h3>{{ remainingDays }}</h3>
          <p>Days</p>
      </div>
      <div>
          <h3>{{ remainingHours }}</h3>
          <p>Hours</p>
      </div>
      <div>
          <h3>{{ remainingMinutes }}</h3>
          <p>Mins</p>
      </div>
    </div>
    <a href="/v5/contact-sales" @click.prevent="handleRedirect()">
      Claim Now
    </a>
    <div class="img-blk" @click="$emit('hidePromo', false)">
      <img  src="@/assets/icon-cancel.svg" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .popup-container {
    background-color: #272727;
    width: size(217);
    padding: size(30) size(30) size(64) size(30);
    position: fixed;
    left: size(30);
    animation: slideup 1s ease-in-out;
    animation-fill-mode: forwards;
    transition: bottom 0.3s ease;
    box-shadow: 12px 12px 25px 0px rgba(0, 0, 0, 0.28);
    border-radius: size(12);
    z-index: var(--slidein-z-index);
    background-image: url('../assets/ipl-banner-img.svg'),
    url('../assets/ipl-banner-img3.svg'), url('../assets/ipl-banner-image2.svg');
    background-position: top 2px left 0, left 0 bottom 0,  right 0 bottom 0;
    background-repeat: no-repeat;

    @keyframes slideup {
      0%{
        bottom: size(-200);
      }
      100%{
        bottom: size(20);
      }
    }
    h2 {
      padding: 0;
      font-size: size(32);
      font-weight: 700;
      color: #F7FD8B;
      line-height: size(36);
      font-family: var(--rz-para-font);

      span {
        display: block;
      }
    }

    .auction-tag {
      font-size: size(16);
      font-weight: 400;
      font-family: var(--rz-para-font);
      color: #fff;
      margin-top: size(6);
      line-height: size(22);
    }

    .time-container {
      display: flex;
      gap: size(28);
      margin-top: size(30);
      margin-bottom: size(45);
      >div {
        display: flex;
        flex-direction: column;
        text-align: center;
        h3 {
          color: #AB94F1;
          font-size: size(40);
          font-weight: 700;
          line-height: size(44);
        }

        p {
          color: #EAEAEA;
          font-size: size(12);
          font-weight: 600;
          line-height: size(15);
          font-family: var(--rz-para-font);
        }
      }

    }

    a {
      padding: size(10) size(18);
      color: var(--rs-black-color);
      margin-bottom: size(48);
      border-radius: size(4);
      background: #F7FD8B;
      cursor: pointer;
      font-size: size(14);
      font-weight: 600;
      line-height: size(14);
    }

    .img-blk {
      position: absolute;
      top: size(12);
      right: size(12);
      cursor: pointer;
    }

    @media screen and (min-width: $breakpoint-md) {
      h2 {
        font-size: size(36);
        line-height: size(40);
      }
    }

    @media screen and (min-width: $breakpoint-lg) {
      h2 {
        font-size: size(48);
        line-height: size(52);
      }
    }
  }
</style>

<script>
import moment from 'moment';

export default {
  name: 'CountDownPopup',
  data() {
    return {
      endDate: moment('2024-11-25T23:59:59'),
      remainingDays: 0,
      remainingHours: 0,
      remainingMinutes: 0,
    };
  },
  mounted() {
    this.getCountdown();
    setInterval(() => {
      this.getCountdown();
    }, 1000 * 60);
  },
  methods: {
    getCountdown() {
      const now = moment();
      if (now.isSameOrAfter(this.endDate)) {
        this.$emit('hidePromo', false);
        return;
      }
      const totalDiff = this.endDate.diff(now);
      const days = Math.floor(totalDiff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((totalDiff / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((totalDiff / (1000 * 60)) % 60);
      this.remainingDays = days;
      this.remainingHours = hours;
      this.remainingMinutes = minutes;
    },
    handleRedirect() {
      this.$emit('hidePromo', false);
      window.location.href = '/v5/contact-sales/';
    },
  },
};
</script>
